export const CategoryEnum = {
  Phone: 1,
  Tablet: 2,
  Laptop: 3,
  Desktop: 4,
  Wearable: 5,
};

export const CategoryEnumDict = {
  [CategoryEnum.Phone]: 'Teléfono',
  [CategoryEnum.Tablet]: 'Tableta',
  [CategoryEnum.Laptop]: 'Computadora Portátil',
  [CategoryEnum.Desktop]: 'Computadora Escritorio',
  [CategoryEnum.Wearable]: 'Usable',
};

export const MembershipEnum = {
  Basic: 1,
  Professional: 2,
  Premium: 3
};

export const AccountTypeEnum = {
  Owner: 1,
  Technical: 2,
  Biller: 3,
};

export const OrderStamp = {
  Warranty: 1,
  Pay: 2,
};

export const ServiceEnum = {
  MicroWelding: 1,
  ChargingPin: 2,
  Screen: 3,
  Keyboards: 4,
  Cleaning: 5,
  Maintenance: 6,
  Wet: 7,
  Check: 8,
  Battery: 9,
  Other: 10,
};

export const ServiceEnumDict = {
  [ServiceEnum.MicroWelding]: 'Microsoldadura',
  [ServiceEnum.ChargingPin]: 'Pin de carga',
  [ServiceEnum.Screen]: 'Instalación de Pantalla',
  [ServiceEnum.Keyboards]: 'Cambio de Teclados',
  [ServiceEnum.Cleaning]: 'Limpieza / Varios',
  [ServiceEnum.Maintenance]: 'Mantenimiento del Equipo',
  [ServiceEnum.Wet]: 'Equipo Mojado / Varios',
  [ServiceEnum.Check]: 'Revisión de Equipo',
  [ServiceEnum.Battery]: 'Instalación de Bateria',
  [ServiceEnum.Other]: 'Otro',
};

export const AmericanCountries = [
  'Antigua y Barbuda',
  'Argentina',
  'Bahamas',
  'Barbados',
  'Belice',
  'Bolivia',
  'Brasil',
  'Canadá',
  'Chile',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Dominica',
  'Ecuador',
  'El Salvador',
  'Estados Unidos',
  'Granada',
  'Guatemala',
  'Guyana',
  'Haití',
  'Honduras',
  'Jamaica',
  'México',
  'Nicaragua',
  'Panamá',
  'Paraguay',
  'Perú',
  'República Dominicana',
  'San Cristóbal y Nieves',
  'San Vicente y las Granadinas',
  'Santa Lucía',
  'Surinam',
  'Trinidad y Tobago',
  'Uruguay',
  'Venezuela',
];

export const EuropeanCountries = [
  'España',
];

export const FileTypeEnum = {
  Order: 1,
  BusinessInfo: 2,
};

export const tag = {
  br: '%0A',
};

export const PermissionEnum = {
  canViewAdministration: 'CanViewAdministration',
  canViewBranchOffices: 'CanViewBranchOffices',
  canViewStatistics: 'CanViewStatistics',
  canViewOrders: 'CanViewOrders',
  canEditOrders: 'CanEditOrders',
  canEditOrdersPrices: 'CanEditOrdersPrices',
  canEditStockQuantities: 'CanEditStockQuantities',
  canCreateOrders: 'CanCreateOrders',
};

export const NotificationTypeEnum = {
  Order: 'Order',
};

export const ContactTypeEnum = {
  Phone: 1,
  Whatsapp: 2,
  Email: 3,
  Other: 4,
};

export const PriorityTypeEnum = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const PriorityTypeEnumDict = {
  [PriorityTypeEnum.Low]: 'Baja',
  [PriorityTypeEnum.Medium]: 'Media',
  [PriorityTypeEnum.High]: 'Alta',
};

export const PriorityOptions = [
  { value: PriorityTypeEnum.Low, label: 'Baja' },
  { value: PriorityTypeEnum.Medium, label: 'Media' },
  { value: PriorityTypeEnum.High, label: 'Alta' },
];

export const OrderTypesEnum = {
  None: 0,
  Mixed: 1,
  Repairs: 2,
  Sales: 3,
};

export const OrderTypes = [
  { value: OrderTypesEnum.None, label: 'N/A' },
  { value: OrderTypesEnum.Mixed, label: 'Reparaciones/Ventas' },
  { value: OrderTypesEnum.Repairs, label: 'Reparaciones' },
  { value: OrderTypesEnum.Sales, label: 'Ventas' },
];

export const DateFilterTypes = {
  Week: 1,
  Month: 2,
  Year: 3,
};

export const DateFilterTypesDict = {
  [DateFilterTypes.Week]: 'Semana',
  [DateFilterTypes.Month]: 'Mes',
  [DateFilterTypes.Year]: 'Año',
};

export const PrintTypes = {
  Letter: 1,
  Thermal: 2,
  Sticker: 3,
};

export const DefaultPrintConfiguration = {
  id: undefined,
  showLogo: true,
  logoX: '100',
  logoY: '100',
  showBusinessName: true,
  showPasscode: true,
};

export const DateFormats = {
  American: "MM/dd/yyyy",
  European: "dd/MM/yyyy",
};

export const OrderStatusIds = {
  PendingAssignment: 'f72e7b04-8ee2-46f6-838a-17e965164690',
  InProgress: '407bdbed-6273-4ea7-90ea-aa5917017f82',
  ToBeDelivered: '540ab1ad-f93d-4e63-b0cd-b5bfa85eb16d',
  Finalized: 'e41a9a3c-4234-4f9f-9b12-50ce624289a6',
  Reject: '10172350-e22f-4cd2-83a1-8a9f31b105da',
  NoSolution: '7e890ec8-1b80-4020-8517-40e2c4e3691a',
  Warranty: 'ee9603b4-cecb-4acf-9626-5ae8dacecc72',
};


export const FileType = {
  Order: 1,
  BusinessInfo: 2,
  PaymentRequestFile: 3,
  OrderPdf: 4,
  OrderSignature: 5,
};
