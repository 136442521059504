import HttpProxy from '@/services/HttpProxy';
import store from '@/store';

export default class AdminService {
  static MyBusiness = {
    read: async () => {
      const response = await HttpProxy.submit({
        method: 'get', url: 'BusinessInfo',
      });
      if (!response?.data) {
        return null;
      }
      store.dispatch('business/read', response.data);
    },
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'BusinessInfo', data,
      });
      if (!response.data) {
        return null;
      }
      store.dispatch('business/update', response.data);
      store.dispatch('account/replaceCurrency', { currencySymbol: response.data.currencySymbol });
    },
  };

  static MyMembership = {
    createPromotionCode: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/AddCoupon', data,
      });
      return response?.data ?? null;
    },
  };

  static UsersAndPermissions = {
    sendInvite: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Sign/send-invitation', data,
      });
      return response?.data ?? null;
    },
    getUsers: async (parameters) => {
      const response = await HttpProxy.submit({
        method: 'get', url: 'Account/ByBusiness', parameters,
      });
      return response?.data ?? null;
    },
    updateAccountType: async (userId, data) => {
      const response = await HttpProxy.submit({
        method: 'put', url: `Account/AccountType/${userId}`, data,
      });
      return response?.data ?? null;
    },
    updatePermit: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/UpdatePermission', data,
      });
      return response?.data ?? null;
    },
  }

  static PoliciesAndTemplates = {
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'BusinessInfo/Policy', data,
      });
      return response?.data ?? null;
    },
    read: async () => {
      const response = await HttpProxy.submit({
        method: 'get', url: 'BusinessInfo/Policy',
      });
      return response?.data ?? null;
    },
  };

  static UploadClients = {
    update: async (data, businessInfoId) => {
      const response = await HttpProxy.submit({
        method: 'post', url: `Clients/UploadFromFile?businessInfoId=${businessInfoId}`, data, headers: {'Content-Type': 'multipart/form-data'}
      });
      return response.status;
    },
  };

  static UploadProducts = {
    update: async (data, businessInfoId) => {
      const response = await HttpProxy.submit({
        method: 'post', url: `Products/UploadFromFile?businessInfoId=${businessInfoId}`, data, headers: {'Content-Type': 'multipart/form-data'}
      });
      return response.status;
    },
  };

  static MyData = {
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/UpdateAccount', data,
      });
      return response?.data ?? null;
    },
  }

  static Security = {
    update: async (data) => {
      const response = await HttpProxy.submit({
        method: 'post', url: 'Account/UpdatePassword', data,
      });
      return response?.data ?? null;
    },
  }

  static GlabalSearch = {
    search: async (query) => {
      const response = await HttpProxy.submit({
        method: 'get', url: `Search?textSearch=${query}`,
      });
      return response?.data ?? null;
    },
  }
}
